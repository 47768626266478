import React, { useState, useEffect, useRef } from "react";
import Divider from "../divider";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "./loader";
import "../../assets/styles/form.css";
import { uploadToBucketURL } from "../../config/project_config";

const Form = () => {
    const [json, setJson] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showModaleditkeys, setShowModaleditkeys] = useState(false);
    const [files, setFiles] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const id = new URLSearchParams(location.search).get("id");
    const token = process.env.ACCESS_TOKEN;
    const sendJsonURL = "https://us-central1-d360-studio-dev.cloudfunctions.net/send_json";
    let sendJsonPayload = undefined;
    const ulRef = useRef({});

    sendJsonPayload = {
        "url": `https://storage.googleapis.com/d360us/imaged/${id}/metadata.json`
    };
    const imageURL = `https://storage.googleapis.com/d360us/imaged/${id}/still.jpg`

    function prepareJson(json_obj) {
        let absolute_keys = ['REPORTNO', 'REPORTDT', 'CERT', 'SHAPE', 'MES1', 'MES2', 'MES3', 'WEIGHT', 'CUT', 'COLOR', 'CLARITY', 'DEPTHPER', 'TABLE', 'GIRDLE', 'CULET', 'POLISH', 'SYMN', 'FLUOR', 'CLARITY CHARACTERISTICS', 'COMMENTS']
        let prop_keys = []
        if (json_obj.PROPERTIES !== undefined)
            prop_keys = Object.keys(json_obj.PROPERTIES)
        let mergedList = [...absolute_keys, ...prop_keys];
        let order_list = [...new Set(mergedList)];
        let newjson = { ...json_obj, PROPERTIES: {} }
        order_list.forEach((key, index) => {
            if (json_obj.PROPERTIES !== undefined && key in json_obj.PROPERTIES) {
                newjson.PROPERTIES[key] = json_obj.PROPERTIES[key];
            }
            else {
                newjson.PROPERTIES[key.toUpperCase()] = ''
            }
        })

        var file_list = []
        if (newjson.hasOwnProperty("USERFILES")) {
            for (var filename of newjson['USERFILES']) {
                file_list.push({ 'name': filename })
            }
            setFiles(file_list)
        }

        return newjson
    }

    const fetchData = async () => {
        try {
            const response = await axios.post(sendJsonURL, { params: sendJsonPayload }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                crossDomains: true,
                timeout: 100000
            });
            setJson(prepareJson(response["data"]["output"]));
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    // escape key to cancel form
    useEffect(() => {
        const handleEscKey = (event) => {
            if (event.key === "Escape") {
                navigate("/dashboard/page");
            }
        };

        window.addEventListener("keydown", handleEscKey);

        return () => {
            window.removeEventListener("keydown", handleEscKey);
        };
    }, [navigate]);

    async function handleSubmit() {
        const filePromises = [...files].map(file => {
            return new Promise((resolve, reject) => {
                if (file instanceof Blob) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        resolve({
                            name: file.name,
                            type: file.type,
                            content: reader.result.split(',')[1] // Remove the base64 prefix
                        });
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                } else {
                    resolve(file); // If the object is not a Blob, simply return what it is
                }
            });
        });

        const encodedFiles = await Promise.all(filePromises);
        let payload = {
            "json_data": json,
            "diamond_id": id,
            "files": encodedFiles,
            "expiryjson": ""
        }
        const uploadToBucket = async () => {
            try {
                axios.defaults.withCredentials = false
                axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
                axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
                await axios.post(uploadToBucketURL, { params: payload }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    crossDomain: true,
                    timeout: 100000
                });
                navigate('/dashboard/page', { state: { success: true } });
            } catch (error) {
                navigate('/dashboard/page', { state: { success: false } });
                console.error(error);
            }
        }
        uploadToBucket();
    }

    function handleCancel() {
        navigate('/dashboard/page', { state: {} });
    }

    // Functions for label remove and view
    function handleaddsubbtn(label) {
        if (ulRef.current[label]) {
            ulRef.current[label].style.opacity = 0.2;
            ulRef.current[label].style.pointerEvents = 'none';
        }
        let updatedJson = json
        delete updatedJson.PROPERTIES[label];
        setJson(updatedJson)
    }

    function handleRemoveLabel() {
        setShowModal(false);
        setShowModaleditkeys(true);
    }

    // Function to handle files
    function removeFiles(index) {
        const newfiles = files.filter((file, i) => i !== index);
        setFiles(newfiles);
    }

    function handleNewFile(event) {
        setFiles([...files, ...event.target.files])
    }

    // Functions to add new field
    function handleAdd() {
        setShowModaleditkeys(false);
        setShowModal(true);
    }

    function handleSubmitNewField() {
        setShowModal(false);
        const key = document.getElementById('key')
        const value = document.getElementById('value')
        handleChange(key.value.toUpperCase(), value.value)
    }

    const removePrefix = (id) => {
        let index = -1;
        if (id.startsWith('ZZ')) {
            index = id.indexOf('-', id.indexOf('-', id.indexOf('-') + 1) + 1);
        } else {
            index = id.indexOf('-', id.indexOf('-') + 1);
        }
        if (index !== -1) {
            return id.substring(index + 1);
        } else {
            return id;
        }
    }

    const handleChange = (key, value) => {
        setJson({
            ...json,
            PROPERTIES: {
                ...json.PROPERTIES,
                [key]: value
            }
        });
    };


    if (loading) {
        return <Loader flag={0} />
    }

    return (
        <>
            {showModal && (
                <div className="form-modal">
                    <div className="form-modal-content">
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowModal(false)}></button>
                        </div>
                        <div className="modal-form">
                            <label className="form-label">label</label>
                            <input style={{ width: '100%' }}
                                type="text"
                                className="form-input-dashboard"
                                id="key"
                            />
                            <label className="form-label" style={{ marginTop: '10px' }}>value</label>
                            <input style={{ width: '100%' }}
                                type="text"
                                className="form-input-dashboard"
                                id="value"
                            />
                            <button className="modal-add-btn" onClick={handleSubmitNewField}>Add Field</button>
                        </div>
                    </div>
                </div>
            )}

            {showModaleditkeys && (
                <div className="form-modal">
                    <div className="form-modal-content" style={{ width: '25rem' }}>
                        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: '10px' }}>
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowModaleditkeys(false)}></button>
                        </div>
                        <div style={{ maxHeight: '500px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                            {Object.keys(json["PROPERTIES"]).map((key) => (
                                <ul ref={(el) => ulRef.current[key] = el} key={key} style={{ display: 'flex', justifyContent: 'space-between' }}>{key}<button className="add-button" onClick={(e) => handleaddsubbtn(key)}>-</button></ul>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            <Divider />

            <div style={{ display: "flex", padding: '23px', 'height': '98vh' }}>
                <div style={{ display: "flex", flexDirection: "column", padding: "30px" }}>
                    <img className="form-img" src={imageURL} alt="Still"></img>
                    <p className="id-heading">Id: {removePrefix(id)}</p>
                    <button className="file-upload-btn" style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 3H12H8C6.34315 3 5 4.34315 5 6V18C5 19.6569 6.34315 21 8 21H11M13.5 3L19 8.625M13.5 3V7.625C13.5 8.17728 13.9477 8.625 14.5 8.625H19M19 8.625V11.8125" stroke="#fffffff" strokeWidth="2"></path>
                            <path d="M17 15V18M17 21V18M17 18H14M17 18H20" stroke="#fffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                        Upload Files
                        <input type="file" multiple onChange={handleNewFile} />
                    </button>
                    <div style={{ width: '100%', maxHeight: '20rem', overflowY: 'auto', scrollbarWidth: 'thin', margin: '10px' }}>
                        {files.map((key, index) => (
                            <div key={index} style={{ width: '80%', display: 'grid', overflowWrap: 'anywhere', gridTemplateColumns: '1.5fr 0.1fr', gap: '1rem', margin: '10px auto', justifyItems: 'baseline' }}>
                                <span>{key.name}</span>
                                <span className="add-button" style={{ marginRight: '0px' }} onClick={(e) => removeFiles(index)}>x</span>
                            </div>
                        ))}
                    </div>
                    <div className="edit-btn" onClick={handleRemoveLabel}>Remove label</div>
                </div>
                <div className="modal-dashboard" style={{ display: "flex", flexDirection: "column" }}>
                    <h2 className="modal-title">Diamond Details</h2>
                    <form className="modal-form">
                        {Object.keys(json["PROPERTIES"]).map((key) => (
                            <div key={key} className="form-group">
                                <label className="form-label">{key}</label>
                                <input style={{ width: '100%' }}
                                    type="text"
                                    className="form-input-dashboard"
                                    value={json["PROPERTIES"][key]}
                                    onChange={(e) => handleChange(key, e.target.value)}
                                />
                            </div>
                        ))}
                    </form>
                    <div className="submit-div">
                        <button type="button" className="add-btn" onClick={handleAdd}>Add Field</button>
                        <button type="button" className="cancel-btn" onClick={handleCancel}>Cancel</button>
                        <input type="submit" value="Submit" id="submit" onClick={handleSubmit}></input>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Form;